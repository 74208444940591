import { useState } from "react";
import { close, logo, menu } from "../assets";
import { navLinks } from "../constants";
import { telegram, whatsapp, instagram, youtube, facebook } from "../assets";
import ReactWhatsapp from 'react-whatsapp';

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="h-14 py-4 flex items-center gap-x-4 bg-[#1D1D1D]">

      <div className="text-white text-[24px] taxt-bold absolute left-24 phone:top-2 phone:left-10 ">
      AAOBATIENKAREIN
      </div>

      <div className="absolute left-80">
      <ul className="list-none sm:flex hidden flex-1 gap-x-20">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-bold  cursor-pointer text-[12px] ${
                active === nav.title ? "text-pink" : "text-dimDarkPink"
              } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
              // onClick={() => setActive(nav.title)}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>
          <div className="sm:hidden hidden flex-1 justify-end items-centers">
          {/* <div className="sm:hidden flex flex-1 justify-end items-centers"> */}
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-pink" : "text-dimDarkRed"
                  } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                  onClick={() => setActive(nav.title)}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="absolute right-10">
        <ul className="flex hidden sm:flex items-center flex-1 gap-x-3 ">
        <ReactWhatsapp number="918850424534" message="Hello, I am interested in booking a tarot reading✨">

          {/* <ReactWhatsapp message="Hello, I want to make a booking!!!"> */}
            <img src={whatsapp} alt="billing" className=" relative z-[5] " />
          </ReactWhatsapp>

          <a href='https://taplink.cc/aaobateinkarein' target="_blank">
            <img src={telegram} alt="billing" className=" relative z-[5] w-6" />
          </a>
          <a href="https://www.instagram.com/aaobateinkarein/" target="_blank">
            <img src={instagram} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.youtube.com/channel/UCBiQ_S2LFOccOy8V8iijO0w" target="_blank">
            <img src={youtube} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.facebook.com/profile.php?id=100067921159142" target="_blank">
            <img src={facebook} alt="billing" className=" relative z-[5] w-5" />
          </a> 
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;