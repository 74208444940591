import { useState } from "react";
import { close, logo, menu } from "../assets";
import { navLinks } from "../constants";
import { telegram, whatsapp, instagram, youtube, facebook } from "../assets";
import ReactWhatsapp from 'react-whatsapp';

const Footer = () => {
  return (
    // <nav className="h-16 py-4 flex items-center gap-x-4 bg-[#000000]">
    <div className="text-sm py-4 flex bg-[#000000]">
      <div className=" text-xs sm:ml-96 sm:pl-56 text-[#FFDF6F] ml-20 pl-1">
      Copyright © 2023. All rights reserved.
      </div>


      <div className="absolute right-10">
        <ul className="flex hidden sm:flex items-center flex-1 gap-x-3 ">
        <ReactWhatsapp number="918850424534" message="Hello, I am interested in booking a tarot reading✨">

          {/* <ReactWhatsapp message="Hello, I want to make a booking!!!"> */}
            <img src={whatsapp} alt="billing" className=" relative z-[5] " />
          </ReactWhatsapp>

          <a href='https://taplink.cc/aaobateinkarein' target="_blank">
            <img src={telegram} alt="billing" className=" relative z-[5] w-6" />
          </a>
          <a href="https://www.instagram.com/aaobateinkarein/" target="_blank">
            <img src={instagram} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.youtube.com/channel/UCBiQ_S2LFOccOy8V8iijO0w" target="_blank">
            <img src={youtube} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.facebook.com/profile.php?id=100067921159142" target="_blank">
            <img src={facebook} alt="billing" className=" relative z-[5] w-5" />
          </a> 
        </ul>
      </div>
    </div>
  );
};

export default Footer;