import React from 'react'
import { top, animation, cardinsta, cardspotify } from "../../assets";
import styles from '../../style';
import Opencard from './opencard';


const animationsection = () => {
  return (
    
    <div id="booking" className='bg-[#1D1D1D]'> 
        <div className='h-10'></div>
        <div className="flex items-center justify-center">
            <img src={top}/>
        </div>
        <div>
        <div className="sm:ml-96 sm:pl-60 ml-24 ">
            <Opencard/>
        </div>
        </div>
          
        <div className='h-64'></div>
        <div className='flex items-center gap-x-64 justify-center'>  
            <a href="https://www.instagram.com/aaobateinkarein/" target="_blank">
                <img src={cardinsta} alt="billing" className="hover:opacity-100 transition  duration-300 ease-in-out cursor-pointer opacity-100 hover:opacity-90 hover:-translate-y-1"/>
            </a>  
            <a href="https://open.spotify.com/show/7rA0Zj41dYnWbYlp3Rt2bl" target="_blank">
                <img src={cardspotify} alt="billing" className="hover:opacity-100 transition  duration-300 ease-in-out cursor-pointer opacity-100 hover:opacity-90 hover:-translate-y-1"/>
            </a>
        </div>
        <div className='h-10'></div>
    </div>
    
    )
}

export default animationsection