import React from 'react'
import './opencard.css'
import { booking, booking2, booking3} from '../../assets'

const Opencard = () => {
  return (
    <div>
      <div className='flex h-2'>
        <div className="wrapper"> 
   
        <div className="squareID" id="two">
          <a href="https://taplink.cc/aaobateinkarein" target="_blank">
            <img className="squareID" id="one" src={booking3}>
            </img>
          </a>
        </div>
        
        <div className="squareID bg-midnight" id="three">
          <a href="https://taplink.cc/aaobateinkarein" target="_blank">
            <img className="squareID" id="one" src={booking3}>
            </img>
          </a>
        </div>
        
        <div className="squareID" id="four">
          <a href="https://taplink.cc/aaobateinkarein" target="_blank">
            <img className="squareID" id="one" src={booking2}>
            </img>
          </a>
        </div>
        
        <div className="squareID" id="five">
          <a href="https://taplink.cc/aaobateinkarein" target="_blank">
            <img className="squareID" id="one" src={booking2}>
            </img>
          </a>
        </div>

        <a href="https://taplink.cc/aaobateinkarein" target="_blank">
          <img className="squareID" id="one" src={booking}>
          </img>
        </a>


        
        
      </div>
      </div>
    </div>
  )
}

export default Opencard