import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from "./pages/Home"
import styles from "./style";

function App() {
  return (
    <div className="App">
        <Navbar />
        <Home />
        <Footer/>
    </div>
  )
}

export default App