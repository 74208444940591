import styles from "../../style";
import { useState } from "react";
import { moon, videos, icon1, icon2, icon3, line } from "../../assets";
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';
// import GetStarted from "./GetStarted";
// import BackgroundAnimation from './backgroundanimation'

const Hero = () => {
  // const [toggle, setToggle] = useState(false);

  return (
    <div id="videos" className="">
      <h1 className="text-center flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] ss:leading-[100.8px] leading-[75px]">
          Latest Videos
      </h1>

      <div className="laptop:hidden">

        <div className="flex item-center justify-center">
          <ReactPlayer 
                width="70%"
                height="90%"
                url="https://www.youtube.com/watch?v=87MctJVOyd4&t=2321s"
              />
        </div>
      <div className="h-2"></div>

      <div className="flex item-center justify-center">
      <ReactPlayer 
                width="70%"
                height="50%"
                url="https://www.youtube.com/watch?v=fTBVlOGbARo&t=18s"
              />
      </div>
      <div className="h-2"></div>
      <div className="flex item-center justify-center">

      <ReactPlayer 
                width="70%"
                height="90%"
                url="https://www.youtube.com/watch?v=8YL4zh5OelM&t=32s"
              /> 
       </div>
       <div className="h-2"></div>

      </div>

      <div className="hidden md:flex">
        <div className="mr-10">
          <div className='bg-[#1D1D1D] rounded-lg w-96 h-64 mt-36 ml-72 mr-10'>
          <div className=""><img className="absolute w-10" src={icon1}></img></div>
            <div className="pt-14 mr-10 ml-10">
              <ReactPlayer 
                width="100%"
                height="90%"
                url="https://www.youtube.com/watch?v=87MctJVOyd4&t=2321s"
              />
            </div>
          </div>
        </div>

        {/* <div className="xs:hidden ss:hidden sm:hidden md:hidden lg:hidden xl:hidden"> */}
          <div className="">
          <div className='bg-[#1D1D1D] rounded-lg w-96 h-64'>
          <img className="absolute w-10" src={icon2}></img>
            <div className="pt-14 mr-10 ml-10">
              <ReactPlayer 
                width="100%"
                height="90%"
                url="https://www.youtube.com/watch?v=fTBVlOGbARo&t=18s"
              />
              </div>
          </div>
          <div className="h-10"></div>
          <div className='bg-[#1D1D1D] rounded-lg w-96 h-64'>
          <img className="absolute w-10" src={icon3}></img>
            <div className="pt-14 mr-10 ml-10">
              <ReactPlayer 
                width="100%"
                height="90%"
                url="https://www.youtube.com/watch?v=8YL4zh5OelM&t=32s"
              /> 
            </div>
          </div>
        </div>
      </div>
      <div className="h-10"></div>
    </div>
  );
};

export default Hero;