import React, {useState, useEffect} from "react";
import styles from "../../style";
import { choose, cardback } from "../../assets";
import { tarot } from "../../constants";
import './pickcard.css'
import { max, set } from 'date-fns';
import {cardback_new, question, question_3, question_final, frame} from "../../assets";

const Pickcard = () => {

  // here are just my hooks i will need to pay attention on clicks, count, first, second and third
  // const [isActive, setIsActive] = useState(false);
  // const [clicks, setClicks] = useState(localStorage.getItem('clicks'));
  // const [num, setNum] = useState(0);
  const [deck, setDeck] = useState([]);
  const [first, setFirst] = useState(0);
  const [second, setSecond] = useState(0);
  const [third, setThird] = useState(0);
  const [count, setCount] = useState(0);
  const [isClick, setIsClick] = useState(true);
  const card1 = localStorage.getItem('card1');
  // const name1 = JSON.parse(window.localStorage.getItem('name1'));
  const name1 = localStorage.getItem('name1');
  const card2 = localStorage.getItem('card2');
  const card3 = localStorage.getItem('card3');
  const [myLocalStorageData, setMyLocalStorageData] = useState({})

  const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

  useEffect(() => {
    function shuffledDeck() {
      setDeck(shuffle(tarot));
      }
    shuffledDeck();

    // if(card1 !== null && name1 !== null){
    //   document.getElementById("mainFrameOne_1").style.display="none"; 
    //   document.getElementById("mainFrameTwo_1").style.display="block";
    //   document.getElementById('savedcard1').src = card1; 
    //   document.getElementById('savedname1').value = name1;
    //   // document.getElementById('setFirst').value = ;
    //   console.log("This is the name for the first card "+ name1) 
    // }else{
    //   document.getElementById("mainFrameOne_1").style.display="block"; 
    //   document.getElementById("mainFrameTwo_1").style.display="none";
    // }

    // if(card2 !== null){
    //   document.getElementById("mainFrameOne_2").style.display="none"; 
    //   document.getElementById("mainFrameTwo_2").style.display="block";
    //   document.getElementById('savedcard2').src = card2; 
    // }else{
    //   document.getElementById("mainFrameOne_2").style.display="block"; 
    //   document.getElementById("mainFrameTwo_2").style.display="none";
    // }

    // if(card3 !== null){
    //   document.getElementById("mainFrameOne_3").style.display="none"; 
    //   document.getElementById("mainFrameTwo_3").style.display="block";
    //   document.getElementById('savedcard3').src = card3; 
    // }else{
    //   document.getElementById("mainFrameOne_3").style.display="block"; 
    //   document.getElementById("mainFrameTwo_3").style.display="none";
    // }
    },
  []); 

function disappear(e) { 
  // setFirst(0);
  // setClicks(0);
  setCount(count + 1)
  if((count == 1 )){
    document.getElementById("mainFrameOne_1").style.display="none"; 
    document.getElementById("mainFrameTwo_1").style.display="block";
    console.log('You clicked '+ count + ' times')
    setFirst(e.target.id);
    // localStorage.setItem("setFirst", e.target.id)
    // localStorage.setItem("card1", tarot[e.target.id].url);
    // localStorage.setItem("name1", tarot[e.target.id].name)
  }else if (count == 2){
    document.getElementById("mainFrameOne_2").style.display="none"; 
    document.getElementById("mainFrameTwo_2").style.display="block";
    console.log('You clicked '+ count + ' times')
    setSecond(e.target.id);
    // localStorage.setItem("card2", tarot[e.target.id].url)
    // localStorage.setItem("name2", tarot[e.target.id].name)
  }else if (count == 3){
    document.getElementById("mainFrameOne_3").style.display="none"; 
    document.getElementById("mainFrameTwo_3").style.display="block"; 
    setThird(e.target.id);
    // localStorage.setItem("card3", tarot[e.target.id].url)
    // localStorage.setItem("name3", tarot[e.target.id].name)
  }else{
    console.log('Do not click more than 3 times');
  }
}


  return (
    <div id="pick" className="bg-[#1D1D1D]">
      {/* <img className="z-5" src={frame} /> */}
      <div>
      </div>
      <h1 className="text-center  text-[#FFFCF3] flex-1 font-poppins  ss:text-[72px] text-[52px] ss:leading-[100.8px] leading-[75px]">
        Choose <span> three </span>{" "} cards
      </h1>
      <div id='trial'></div>

      <div className={`inline-block ${styles.flexCenter} `}>
        <div className='flex w-150px h-75px m-50' id="tarot" >
          {deck.map((tarot) => (
            <div >
              <div className='grid grid-cols-12 '>
                <div className='row-span-full col-start-3 col-span-12 self-center'>
                    <img style={{pointerEvents: count < 4 ? '' : 'none' }} className=" w-[100%] h-[100%] hover:opacity-100 transition  duration-300 ease-in-out cursor-pointer opacity-100 hover:opacity-50	hover:-translate-y-1" id={tarot.id} onClick={disappear} src={cardback}></img>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='sm:h-12' alt='empty div'></div>
      <div className={`${styles.flexCenter} inline-block grid grid-flow-col gap-5 content-evenly justify-center `}>
          <div>
          {/* <p className={`${styles.flexCenter} font-bold text-[#C95672] sm:ml-10 ml-0 sm:mt-0 mt-10` } >SELF</p> */}
          <div id="mainFrameOne_1">
            <div>
              <img className="h-20 sm:h-96 sm:w-54 items-center" src={question_final}/>
              {/* <p className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`}>NAME</p> */}
            </div>
          </div>
          <div id="mainFrameTwo_1"  className='mainFrameTwo' >
            <div className="changed">
              <img id="savedcard1" className="h-20 sm:h-96 sm:w-54 items-center" src={tarot[first].url}/>
              {/* <p id="savedname1" className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`} name="name" >{tarot[first].name}</p> */}
            </div>
          </div>
        </div>

        <div>
          {/* <p className={`${styles.flexCenter} font-bold text-[#C95672] sm:ml-10 ml-0 sm:mt-0 mt-10`} >SITUATION</p> */}
          <div id="mainFrameOne_2">
            <div>
              <img  className="h-20 sm:h-96 sm:w-54 items-center" src={question_final}/>
              {/* <a className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`}>NAME</a> */}
            </div>
          </div>
          <div id="mainFrameTwo_2" className='mainFrameTwo' >
            <img id="savedcard2" className="h-20 sm:h-96 sm:w-54 items-center" src={tarot[second].url}/>
            {/* <a className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`}>{tarot[second].name}</a> */}
          </div>
        </div>

        <div>
          {/* <p className={`${styles.flexCenter} font-bold text-[#C95672] sm:ml-10 ml-0 sm:mt-0 mt-10`} >CHALLENGES</p> */}
          <div id="mainFrameOne_3">
            <div>
              <img className="h-20 sm:h-96 sm:w-54 items-center" src={question_final}/>
              {/* <a className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`}>NAME</a> */}
            </div>
          </div>
          <div id="mainFrameTwo_3" className='mainFrameTwo' >
            <div>
            {/* <p className={`${styles.flexCenter} font-bold text-[#C95672] sm:ml-10 ml-0 sm:mt-0 mt-10`} >CHALLENGES</p> */}
            {/* <img className="h-20 sm:h-96 sm:w-54 items-center mx-{8} ml-6" src={tarot[third].url}/> */}
              <img id="savedcard3" className="h-20 sm:h-96 sm:w-54 items-center" src={tarot[third].url}/>
              {/* <a className={`${styles.flexCenter} text-sm text-[#FFDF6F] sm:ml-4 ml-0 sm:mt-0 mt-10`}>{tarot[third].name}</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className='sm:h-3' alt='empty div'></div>
      <div className={`${styles.flexCenter} sm:ml-10 sm:mt-0 mt-2`} >
        <a href="https://www.youtube.com/@PoojaTarotReader/streams" target="_blank" style={{pointerEvents: count > 2 ? '' : 'none' }}>
          <button  href="https://www.youtube.com/@PoojaTarotReader/streams" style={{pointerEvents: count > 2 ? '' : 'none' }} className = "w-60 h-10 rounded-lg transition ease-in-out delay-150 bg-[#D9D9D9] text-black font-bold hover:-translate-y-1 hover:scale-110 hover:bg-white hover:text-[#88665D] hover:border-2 hover:border-[#88665D]	duration-300 sm:mr-9">
            REVEAL THE MEANING
          </button>
          <div className="h-3"></div>
          {/* <p className="sm:text-xs	text-center text-[#FFFFFF]">*First time chosen card are your cards for the day</p> */}
          <div className="h-3"></div>
        </a>
        
      </div>
      <p className="sm:text-xs	text-center text-[#FFFFFF]">*First time chosen cards are your cards for the day</p>
    </div>
  )
}

export default Pickcard
