import styles from "../../style";
import { thanks1, moon2, star } from "../../assets";

const Hero = () => {
  return (
    <div id="whoami">
        <h1 className="text-center flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] ss:leading-[100.8px] leading-[75px]">
        Who am I? 
        </h1>
        <h1 className="text-center  flex-1 ss:text-[22px] text-[12px]">
        Hi , Welcome to AAOBATEINKAREIN!  Pooja Here <br/>I am a Shivbhakt, Women, Maa, Friend, Tarot Reader, Foodie & Luxurious Traveler.<br/>Living a 3D life with 5D Consciousness .

        </h1>
      <section id="home" className={`flex md:flex-row ${styles.paddingY}`}>
        
      <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
          <p className="text-center text-[16px] phone:text-[32px] ">
          I started, actually pushed by the Universe to start AAOBATEINKAREIN in 7/05/2020 by a few Tower Moments in my personal life. I channeled all my Talents, Powers, Blessings and Gut Instincts together to start AAOBATEINKAREIN. For myself and millions of people in the world, who are on their Spiritual Journey, Spiritual Awakening, or just leading a Life Searching for Answers or Just Happiness.
          </p>
        </div>

        <div className={` hidden flex-1 md:flex ${styles.flexCenter} md:my-0 my-10 relative`}>
          <img src={moon2} alt="billing" className="phone:w-[70%] w-[40%] relative z-[5]" />
        </div>

      </section>
      <img src={star} alt="billing" className="absolute phone:hidden ml-40" />
      <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
        

          <img src={thanks1} alt="billing" className="phone:w-[60%] w-[90%] relative z-[5]" />
        </div>
    </div>
  );
};

export default Hero;