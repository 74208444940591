import styles from "../../style";
import { moon } from "../../assets";
import { telegram, whatsapp, instagram, youtube, facebook } from "../../assets";
import ReactWhatsapp from 'react-whatsapp';


const Hero = () => {
  return (
    <div className="">
        <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
          <div>
            <div>
              <h1 className="max-w-[400px] text-center flex-1 ss:text-[42px] text-[22px] ss:leading-[100.8px]">
                Welcome to AAOBATIENKAREIN!
              </h1>
            </div>
            <div className="text-right">
              <h1>POOJA TAROT READER</h1>
            </div>
            <div>
            <ul className="flex justify-center ml-20 mt-2 sm:hidden items-center flex-1 gap-x-3 ">
          <ReactWhatsapp number="918850424534" message="Hello, I am interested in booking a tarot reading✨">

          {/* <ReactWhatsapp message="Hello, I want to make a booking!!!"> */}
            <img src={whatsapp} alt="billing" className=" relative z-[5] " />
          </ReactWhatsapp>

          <a href='https://taplink.cc/aaobateinkarein' target="_blank">
            <img src={telegram} alt="billing" className=" relative z-[5] w-6" />
          </a>
          <a href="https://www.instagram.com/aaobateinkarein/" target="_blank">
            <img src={instagram} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.youtube.com/channel/UCBiQ_S2LFOccOy8V8iijO0w" target="_blank">
            <img src={youtube} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.facebook.com/profile.php?id=100067921159142" target="_blank">
            <img src={facebook} alt="billing" className=" relative z-[5] w-5" />
          </a> 
        </ul>
            </div>
          </div>
          <img src={moon} alt="billing" className="w-[24%]" />
        </div>    

    </div>
  );
};

export default Hero;

{/* <div className="">
<h1 className="max-w-[500px] text-center  ss:text-[28px] text-[16px] ss:leading-[100.8px] leading-[75px]">
POOJA TAROT READER
</h1>
</div> */}