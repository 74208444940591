import React from 'react'
import Introduction from '../containers/home/introduction'
import Pickcard from '../containers/home/pickcard'
import WhoAmI from '../containers/home/whoami'
import Latestvideos from '../containers/home/latestvideos'
import Animation from '../containers/home/animation'
import Animation_present from '../constants/animation'
import { navLinks } from "../constants";
import Opencard from '../containers/home/opencard'


const Home = () => {
  return (
    <div>
      <Introduction/>
      <Pickcard/>
      <WhoAmI/>   
      <Animation/>
      {/* <div className='flex items-center justify-center'> */}
        {/* <Opencard/> */}
      {/* </div> */}
      <Latestvideos />
      <div className='bg-[#1D1D1D] h-100'>
        <Animation_present/>
      </div>
    </div>
  )
}

export default Home