import React from 'react'
import './animation.css';
import giftcardyellow from '../assets/giftcard.png';
import { telegram, whatsapp, instagram, youtube, facebook } from "../../src/assets";
import ReactWhatsapp from 'react-whatsapp';

const animation = () => {
  return (
    <>
        <div id="gift" className="sm:ml-96 sm:pl-56 ml-16 pl-3">
            <div className='h-20'></div>
            <div className='content-center h-96'>
                <div class="container">
                    <div class="row">
                        <div class="col-12 flex items-center justify-content-center">
                        <div  class="box">
                            <div class="box-body">
                            <a href="https://www.amazon.in/hz/wishlist/ls/2CQCPAZJ9P5DW?ref_=list_d_wl_lfu_nav_5" target="_blank"><img class="img" src={giftcardyellow}/></a>
                            <div class="box-lid">
                                
                                <div class="box-bowtie"></div>
                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul className="flex justify-center sm:hidden items-center flex-1 gap-x-3 ">
        <ReactWhatsapp number="918850424534" message="Hello, I am interested in booking a tarot reading✨">

          {/* <ReactWhatsapp message="Hello, I want to make a booking!!!"> */}
            <img src={whatsapp} alt="billing" className=" relative z-[5] " />
          </ReactWhatsapp>

          <a href='https://taplink.cc/aaobateinkarein' target="_blank">
            <img src={telegram} alt="billing" className=" relative z-[5] w-6" />
          </a>
          <a href="https://www.instagram.com/aaobateinkarein/" target="_blank">
            <img src={instagram} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.youtube.com/channel/UCBiQ_S2LFOccOy8V8iijO0w" target="_blank">
            <img src={youtube} alt="billing" className=" relative z-[5] w-5" />
          </a> 
          <a href="https://www.facebook.com/profile.php?id=100067921159142" target="_blank">
            <img src={facebook} alt="billing" className=" relative z-[5] w-5" />
          </a> 
        </ul>
        <div className='h-2'></div>
        </>
  )
}

export default animation